import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Fab, Theme, useScrollTrigger, Zoom } from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { scrollToCategory } from '../utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function BackToTop() {
  const { classes } = useStyles();
  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 200,
  });

  return (
    <Zoom in={trigger}>
      <div
        onClick={() => scrollToCategory(0)}
        role="presentation"
        className={classes.root}
      >
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
}
