import React from 'react';

import { Button, Divider, List, ListItem, ListItemText } from '@mui/material';

import { scrollToCategory } from '../../utils';
import { EWedType, IWedType } from '../../types';
import {
  CATEGORIES_HOME,
  CATEGORIES_LUNCH,
  CATEGORIES_PARTY,
} from '../../constants';

export default function Info(props: IWedType) {
  const { wedType } = props;
  return (
    <React.Fragment>
      <List>
        {wedType !== EWedType.Home && (
          <>
            <ListItem>
              <ListItemText
                primary="Prosíme, aby hosté zvaní na oběd, přicházeli do kulturního domu zadním vchodem - od paneláků."
                secondary="Hosty přicházející na večerní párty rádi přivítáme u hlavního vchodu - od kostela."
              />
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem>
          <ListItemText
            primary="Prosíme, nedělejte nepořádek u nevěsty na zahradě v předvečer svatby."
            secondary="Nikomu by to radost neudělalo a bez této tradice se rádi obejdeme."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Prosíme, neparkujte před vchodem do kostela v Rapotíně. Využijte
                místa uvedená v sekci
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(
                      Object.keys(
                        wedType === EWedType.Home
                          ? CATEGORIES_HOME
                          : wedType === EWedType.Lunch
                          ? CATEGORIES_LUNCH
                          : CATEGORIES_PARTY
                      ).indexOf('Parkování')
                    )
                  }
                >
                  Parkování
                </Button>
                .
              </>
            }
            secondary="Místo je rezervováno pro nevěstu."
          />
        </ListItem>
        <Divider />
        {wedType !== EWedType.Home && (
          <>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    Prosíme, neparkujte před vchodem do kulturního domu v Bludově.
                    Využijte místa uvedená v sekci
                    <Button
                      color="primary"
                      key={`kontaktyButton`}
                      onClick={() =>
                        scrollToCategory(
                          Object.keys(
                            wedType === EWedType.Lunch
                              ? CATEGORIES_LUNCH
                              : CATEGORIES_PARTY
                          ).indexOf('Parkování')
                        )
                      }
                    >
                      Parkování
                    </Button>
                    .
                  </>
                }
                secondary="Místo je rezervováno pro novomanžele a svatebního řidiče."
              />
            </ListItem>
            <Divider />
          </>
        )}
        {wedType !== EWedType.Home && (
          <>
            <ListItem>
              <ListItemText
                primary="Prosíme, nepřipravujte únos nevěsty."
                secondary="Rádi bychom prožili svatební den spolu a se svatebními hosty."
              />
            </ListItem>
            <Divider />
          </>
        )}
        {wedType !== EWedType.Home && (
          <>
            <ListItem>
              <ListItemText
                primary="Ubytování pro svatební hosty"
                secondary={
                  <>
                    Detailní informace ohledně možnosti ubytování poskytne
                    <Button
                      color="primary"
                      key={`kontaktyButton`}
                      onClick={() =>
                        scrollToCategory(
                          Object.keys(
                            wedType === EWedType.Lunch
                              ? CATEGORIES_LUNCH
                              : CATEGORIES_PARTY
                          ).indexOf('Snoubenci')
                        )
                      }
                    >
                      Nevěsta
                    </Button>
                  </>
                }
              />
            </ListItem>
            <Divider />
          </>
        )}
        {wedType !== EWedType.Home && (
          <>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    Pokud potřebujete ze svatby odvézt, neváhejte kontaktovat
                    <Button
                      color="primary"
                      key={`kontaktyButton`}
                      onClick={() =>
                        scrollToCategory(
                          Object.keys(
                            wedType === EWedType.Lunch
                              ? CATEGORIES_LUNCH
                              : CATEGORIES_PARTY
                          ).indexOf('Řidič')
                        )
                      }
                    >
                      Svatebního řidiče
                    </Button>
                  </>
                }
              />
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem>
          <ListItemText
            primary={
              <>
                Do dne svatby se prosím obracejte s dotazy na{' '}
                <Button
                  color="primary"
                  key={'snoubenci'}
                  onClick={() => scrollToCategory(2)}
                >
                  SNOUBENCE
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                V den svatby se prosím obracejte na
                {
                  <Button
                    color="primary"
                    key={`kontaktyButton`}
                    onClick={() =>
                      scrollToCategory(
                        Object.keys(
                          wedType === EWedType.Home
                            ? CATEGORIES_HOME
                            : wedType === EWedType.Lunch
                            ? CATEGORIES_LUNCH
                            : CATEGORIES_PARTY
                        ).indexOf('Kontakty')
                      )
                    }
                  >
                    Zdislavu, Janu a Tondu
                  </Button>
                }
              </>
            }
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
}
