import { Grid } from "@mui/material";
import React from "react";

export default function Video() {
  const width = (+window.innerWidth > 1200? 1200: +window.innerWidth) - 32; // 2x16 padding
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
          <iframe
            style={{marginLeft: 16}}
            width={width}
            height={width * 0.5625}
            src="https://www.youtube.com/embed/uDZg3mhfO_A?si=W8PcKtlSAJA8EuWs"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
      </Grid>
    </React.Fragment>
  );
}
