import React from 'react';

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { IPhotoCard } from '../types';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    width: '100%',
    display: 'block',
    position: 'relative',
    maxWidth: 345,
    margin: '0 auto',
    textAlign: 'center',
    '&:hover': {
      '& $cardContent': {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
    },
  },
  cardContent: {
    position: 'absolute',
    width: '100%',
    bottom: '0px',
    left: '0px',
    alignItems: 'center',
    textAlign: 'center',
    backgroundImage:
      'linear-gradient( to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1))',
  },
  media: {
    height: 340,
  },
}));

export default function PhotoCard(props: IPhotoCard) {
  const {
    imagePaths,
    title,
    description,
    zoneramaLink,
    gPhotosLink,
    oneDriveLink,
    onClick,
    style,
  } = props;
  const { classes } = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card className={classes.card} raised onClick={onClick} style={style}>
        {
          // animation disabled
          /* <Carousel
          animation={'fade'}
          autoPlay={true}
          stopAutoPlayOnHover={true}
          interval={8000}
          indicators={false}
        >
          {imagePaths.map((imagePath) => (
            <CardMedia
              key={imagePath}
              className={classes.media}
              image={`${imagePath}.jpg`}
            />
          ))}
        </Carousel> */
        }
        <Link href={zoneramaLink} key={'zonerama'} target="_blank" rel="noopener">
          <CardMedia
            key={imagePaths[0]}
            className={classes.media}
            image={`/photos/${imagePaths[0]}.jpg`}
          />
        </Link>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5">
            {zoneramaLink ? (
              <Link
                href={zoneramaLink}
                key={'zonerama'}
                target="_blank"
                rel="noopener"
              >
                {title}
              </Link>
            ) : (
              title
            )}
          </Typography>
          <Typography gutterBottom variant="body1">
            {description}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {zoneramaLink ? (
              <Link
                href={zoneramaLink}
                key={'zonerama'}
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Zonerama
              </Link>
            ) : null}
            {gPhotosLink ? (
              <Link
                href={gPhotosLink}
                key={'gphotos'}
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Google fotky
              </Link>
            ) : null}
            {oneDriveLink ? (
              <Link
                href={oneDriveLink}
                key={'oneDrive'}
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                One drive
              </Link>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
