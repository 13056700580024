import React from 'react';

import { Link, Typography } from '@mui/material';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://znoj.cz/">
        znoj.cz
      </Link>{' '}
      2021
    </Typography>
  );
}
