import React from 'react';

import { Typography } from '@mui/material';

export default function Gifts() {
  return (
    <Typography align="center">
      Nevíte si rady s dary? Nápověda už je tady...
      <br />
      Když korunkou přispějete, budeme moc rádi,
      <br />
      důležitější však pro nás bude,
      <br />
      že budete s námi!
    </Typography>
  );
}
