import React from 'react';

import { Grid } from '@mui/material';

import PersonCard from '../../Components/PersonCard';

export default function We() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <PersonCard imagePath={'/people/d'} title={'Daňka'} description={'Nevěsta'} />
      <PersonCard imagePath={'/people/j'} title={'Jirka'} description={'Ženich'} />
    </Grid>
  );
}
