import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Box, Paper, TableContainer, Typography } from '@mui/material';
import ProgramTabPanel from '../../Components/ProgramTabPanel';

const useStyles = makeStyles()({
  tableContainer: {
    maxWidth: 600,
    minWidth: 300,
  },
  box: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export default function Banquet() {
  const { classes } = useStyles();

  return (
    <>
      <Typography variant="h5" align="center">
        Vybrané kousky z rautu
      </Typography>
      <Box my={4} className={classes.box}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <ProgramTabPanel
            value={1}
            index={1}
            customRows={[
              {
                time: 'cca od 17:00',
                activity:
                  'saláty, obložené mísy, řízečky, pečené kuřecí paličky, smažený květák, ...',
              },
              { time: 'od 18:00', activity: 'Pečené sele' },
              {
                time: 'od 19:30',
                activity: 'Hovězí guláš',
              },
              {
                time: 'od 21:30',
                activity: 'Tatarák',
              },
            ]}
          />
        </TableContainer>
      </Box>
    </>
  );
}
