export type CategoriesType = Array<[string, JSX.Element]>

export interface IPlaceCard {
    imagePaths: string[];
    mapLink?: string;
    gMapLink?: string;
    title: string;
    description: string;
    onClick?: () => void;
    style?: any;
}
export interface IPhotoCard {
    imagePaths: string[];
    zoneramaLink?: string;
    gPhotosLink?: string;
    oneDriveLink?: string;
    title: string;
    description: string;
    onClick?: () => void;
    style?: any;
}
export interface ICategoriesProps {
    categories: Array<[string, JSX.Element]>,
    wedType: EWedType
}

export interface ICategoriesKeysProps {
    categories: Array<string>
}

export enum EWedType {
    Home = 0,
    Lunch = 1,
    Party = 2,
    Print = 3
}

export interface IWedType {
    wedType: EWedType
}

export type tableDataType = Array<{
    time: string,
    activity: string
}>