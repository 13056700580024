import React, { useEffect, useState } from 'react';

import { Container, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Carousel from 'react-material-ui-carousel';

import { getRandomizedArray } from '../../utils';
import CarouselItem from './CarouselItem';

const useStyles = makeStyles()((theme: Theme) => ({
  carousel: {
    position: 'relative',
    margin: '0 auto',
    padding: '0',
  },
  slideshowContainer: {
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(-4),
    },
  },
}));

export default function TopCarousel() {
  const { classes } = useStyles();
  const [imagesArray, setImagesArray] = useState([1]);
  useEffect(() => {
    const randomizedArray: number[] = getRandomizedArray(
      Array.from(Array(320).keys())
    );
    setImagesArray(randomizedArray);
  }, []);
  return (
    <Container maxWidth="lg" className={classes.slideshowContainer}>
      <Carousel
        animation={'slide'}
        autoPlay={true}
        stopAutoPlayOnHover={true}
        className={classes.carousel}
        indicators={false}
      >
        {imagesArray.map((item: number) => (
          <CarouselItem key={item} imagePath={'' + (+item + 1)} />
        ))}
      </Carousel>
    </Container>
  );
}
