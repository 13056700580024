import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ['Nunito', 'Tangerine'].join(','),
  },
  palette: {
    primary: {
      main: '#7f1734',
    },
    secondary: {
      main: '#f0f0f0',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
