import React from 'react';

import { Grid } from '@mui/material';

import PersonCard from '../../Components/PersonCard';

export default function Driver() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PersonCard
          imagePath={'/people/ridic'}
          title={'Roman Jurenka'}
          description={<a href="#">Řidič Roman</a>}
        />
        <PersonCard
          imagePath={'/people/ridic2'}
          title={'Honza Donoval'}
          description={<a href="#">Řidič Honza</a>}
        />
        <PersonCard
          imagePath={'/people/ridic3'}
          title={'Jura Stratil'}
          description={<a href="#">Řidič Jura</a>}
        />
      </Grid>
    </React.Fragment>
  );
}
