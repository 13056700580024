import React from 'react';

import { Grid } from '@mui/material';

import PlaceCard from '../../Components/PlaceCard';
import { EWedType, IWedType } from '../../types';

export default function Parking(props: IWedType) {
  const { wedType } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <PlaceCard
        key={'rap-park'}
        imagePaths={['/places/rapotinPark']}
        mapLink={'https://mapy.cz/s/pugapatevo'}
        title={'Obřad - parkování'}
        description={'Kostel RAPOTÍN'}
        onClick={() =>
          window.open(
            '/places/rapotinParkFull.jpg',
            'popUpWindow',
            'height=800,width=800,scrollbars=yes,menubar=no'
          )
        }
        style={{ cursor: 'pointer' }}
      />
      {wedType !== EWedType.Home && (
        <PlaceCard
          key={'blud-park'}
          imagePaths={['/places/bludovPark']}
          mapLink={'https://mapy.cz/s/dagekavoju'}
          title={'Rodinný oběd, Večerní párty - parkování'}
          description={'Obecní úřad BLUDOV'}
          onClick={() =>
            window.open(
              '/places/bludovParkFull.jpg',
              'popUpWindow',
              'height=800,width=800,left=10,top=10,,scrollbars=yes,menubar=no'
            )
          }
          style={{ cursor: 'pointer' }}
        />
      )}
    </Grid>
  );
}
