import React from 'react';

import { Grid } from '@mui/material';

import PersonCard from '../../Components/PersonCard';

export default function Contacts() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PersonCard
          imagePath={'/people/zdislava'}
          title={'Zdislava Štachová'}
          description={<a href="#">Svědkyně</a>} //"tel:"
        />
        <PersonCard
          imagePath={'/people/jana'}
          title={'Jana Klíčová'}
          description={<a href="#">Klíčová sestra</a>}
        />
        <PersonCard
          imagePath={'/people/tonda'}
          title={'Tonda Šťastný'}
          description={<a href="#">Svědek</a>}
        />
      </Grid>
    </React.Fragment>
  );
}
