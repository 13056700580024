import './App.css';

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Box, Container, Divider, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import AppContent from './AppContent';
import BackToTop from './Components/BackToTop';
import Navigation from './Components/Navigation';
import TopCarousel from './Components/TopCarousel';
import Copyright from './Pages/Common/Copyright';
import {
  CATEGORIES_HOME,
  CATEGORIES_LUNCH,
  CATEGORIES_PARTY,
  CATEGORIES_PRINT,
} from './constants';
import { EWedType } from './types';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    'scroll-behavior': 'smooth',
  },
  categoryZeroContainer: {
    margin: 0,
    padding: 0,
  },
  lastDivider: {
    margin: '64px 0 32px 0',
  },
}));

export default function App() {
  const { classes } = useStyles();

  return (
    <React.Fragment>
      <div id="category-0" key={0} className={classes.categoryZeroContainer} />
      <TopCarousel />
      <Router>
        <Switch>
          <Route path="/r2">
            <Navigation categories={Object.keys(CATEGORIES_LUNCH)} />
          </Route>
          <Route path="/vp">
            <Navigation categories={Object.keys(CATEGORIES_PARTY)} />
          </Route>
          <Route path="/tisk">
            <Navigation categories={Object.keys(CATEGORIES_PRINT)} />
          </Route>
          <Route path="/">
            <Navigation categories={Object.keys(CATEGORIES_HOME)} />
          </Route>
        </Switch>
      </Router>
      <Container maxWidth="lg">
        <Box my={4} style={{ marginTop: 0 }}>
          <Router>
            <Switch>
              <Route path="/r2">
                <AppContent
                  categories={Object.entries(CATEGORIES_LUNCH)}
                  wedType={EWedType.Lunch}
                />
              </Route>
              <Route path="/vp">
                <AppContent
                  categories={Object.entries(CATEGORIES_PARTY)}
                  wedType={EWedType.Party}
                />
              </Route>
              <Route path="/tisk">
                <AppContent
                  categories={Object.entries(CATEGORIES_PRINT)}
                  wedType={EWedType.Home}
                />
              </Route>
              <Route path="/">
                <AppContent
                  categories={Object.entries(CATEGORIES_HOME)}
                  wedType={EWedType.Home}
                />
              </Route>
            </Switch>
          </Router>
          <Divider variant="middle" light className={classes.lastDivider} />
          <Copyright />
        </Box>
        <BackToTop />
      </Container>
    </React.Fragment>
  );
}
