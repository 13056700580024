import 'react-image-gallery/styles/css/image-gallery.css';
import './Invitation.css';

import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';

import { Paper, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { EWedType, IWedType } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    width: '245px',
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const images = [
  {
    fullscreen: '/invite/full.jpg',
    original: '/invite/mini.jpg',
    originalClass: 'thumbnail',
  },
  {
    fullscreen: '/invite/lunchFull.jpg',
    original: '/invite/lunchMini.jpg',
    originalClass: 'thumbnail',
  },
  {
    fullscreen: '/invite/partyFull.jpg',
    original: '/invite/partyMini.jpg',
    originalClass: 'thumbnail',
  },
];

export default function Invitation(props: IWedType) {
  const { classes } = useStyles();
  const { wedType } = props;
  const [fullScreenChecker, setFullScreenChecker] = useState(-2);
  const [fullScreen, setFullScreen] = useState(-1);

  const imagesToShow =
    wedType === EWedType.Home
      ? [images[0]]
      : wedType === EWedType.Party
      ? [images[0], images[2]]
      : images;

  return (
    <div className={classes.root}>
      {imagesToShow.map((image, index) => (
        <Paper key={index} className={classes.paper} elevation={3}>
          <ImageGallery
            items={[image]}
            showNav={false}
            showThumbnails={false}
            showPlayButton={false}
            onClick={() => setFullScreenChecker(fullScreen >= 0 ? -1 : index)}
            onScreenChange={(full: boolean) => {
              full && setFullScreen(index);
            }}
            ref={
              fullScreenChecker === index
                ? (el) => {
                    setFullScreenChecker(-2);
                    el?.fullScreen();
                  }
                : fullScreenChecker === -1 && fullScreen === index
                ? (el) => {
                    setFullScreenChecker(-2);
                    setFullScreen(-1);
                    el?.exitFullScreen();
                  }
                : null
            }
          />
        </Paper>
      ))}
    </div>
  );
}
