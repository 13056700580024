import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Box, Paper, TableContainer } from '@mui/material';

import ProgramTabPanel from '../../Components/ProgramTabPanel';

const useStyles = makeStyles()({
  tableContainer: {
    maxWidth: 600,
    minWidth: 300,
  },
  box: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export default function LunchMenu() {
  const { classes } = useStyles();

  return (
    <>
      <Box my={4} className={classes.box}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <ProgramTabPanel
            value={1}
            index={1}
            customRows={[
              { time: 'Polévka', activity: 'Hovězí vývar s játrovými knedlíčky' },
              {
                time: 'Hlavní chod',
                activity: 'Kuřecí plátek se šunkou a sýrem, se šťouchanými brambory',
              },
            ]}
          />
        </TableContainer>
      </Box>
    </>
  );
}
