import React from 'react';

import { Box, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { EWedType, ICategoriesProps } from './types';

const useStyles = makeStyles()((theme: Theme) => ({
  categoryContainer: { paddingTop: 64 },
  title: { paddingTop: 16 },
  categoryTitle: {
    paddingBottom: '16px',
  },
}));

export default function AppContent(props: ICategoriesProps) {
  const { categories, wedType } = props;
  const { classes } = useStyles();

  return (
    <React.Fragment>
      {
        <Typography
          variant="h6"
          color="primary"
          align="center"
          className={classes.title}
        >
          <b>{wedType !== EWedType.Home && <>Stránky pro zvané na</>}</b>
        </Typography>
      }
      {
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={classes.title}
        >
          <b>
            {wedType === EWedType.Lunch ? (
              <>Rodinný oběd</>
            ) : wedType === EWedType.Party ? (
              <>Večerní párty</>
            ) : null}
          </b>
        </Typography>
      }
      {categories.slice(1).map((category: [string, JSX.Element], index: number) => (
        <div
          id={`category-${index + 1}`}
          key={index + 1}
          className={classes.categoryContainer}
        >
          <Box mb={0}>
            <Divider variant="middle" light />
            <Typography variant="h5" className={classes.categoryTitle}>
              {category[0]}
            </Typography>
            {category[1]}
          </Box>
        </div>
      ))}
    </React.Fragment>
  );
}
