import React from 'react';

import Contacts from './Pages/Home/Contacts';
import Gifts from './Pages/Home/Gifts';
import Info from './Pages/Home/Info';
import Invitation from './Pages/Home/Invitation';
import Places from './Pages/Home/Places';
import Program from './Pages/Home/Program';
import We from './Pages/Home/We';
import Date from './Pages/Home/Date';
import { EWedType } from './types';
import LunchMenu from './Pages/Lunch/LunchMenu';
import Banquet from './Pages/Home/Banquet';
import Driver from './Pages/Home/Driver';
import Parking from './Pages/Home/Parking';
import Photos from './Pages/Home/Photos';
import Video from './Pages/Home/Video';

export const DRAWER_WIDTH = 240;

export const CATEGORIES_HOME = {
  Úvod: <React.Fragment></React.Fragment>,
  Datum: <Date />,
  Snoubenci: <We />,
  Fotky: <Photos />,
  Video: <Video />,
  Oznámení: <Invitation wedType={EWedType.Home} />,
  Informace: <Info wedType={EWedType.Home} />,
  Program: <Program wedType={EWedType.Home} />,
  Místa: <Places />,
  Parkování: <Parking wedType={EWedType.Home} />,
  Dary: <Gifts />,
  Kontakty: <Contacts />,
};

export const CATEGORIES_LUNCH = {
  Úvod: <React.Fragment></React.Fragment>,
  Datum: <Date />,
  Snoubenci: <We />,
  Fotky: <Photos />,
  Pozvánka: <Invitation wedType={EWedType.Lunch} />,
  Informace: <Info wedType={EWedType.Lunch} />,
  Program: <Program wedType={EWedType.Lunch} />,
  Oběd: <LunchMenu />,
  Raut: <Banquet />,
  Místa: <Places />,
  Parkování: <Parking wedType={EWedType.Lunch} />,
  Dary: <Gifts />,
  Kontakty: <Contacts />,
  Řidič: <Driver />,
};

export const CATEGORIES_PARTY = {
  Úvod: <React.Fragment></React.Fragment>,
  Datum: <Date />,
  Snoubenci: <We />,
  Fotky: <Photos />,
  Pozvánka: <Invitation wedType={EWedType.Party} />,
  Informace: <Info wedType={EWedType.Party} />,
  Program: <Program wedType={EWedType.Party} />,
  Raut: <Banquet />,
  Místa: <Places />,
  Parkování: <Parking wedType={EWedType.Party} />,
  Dary: <Gifts />,
  Kontakty: <Contacts />,
  Řidič: <Driver />,
};

export const CATEGORIES_PRINT = {
  Úvod: <React.Fragment></React.Fragment>,
  Datum: <Date />,
  Snoubenci: <We />,
  Fotky: <Photos />,
  Pozvánka: <Invitation wedType={EWedType.Lunch} />,
  Informace: <Info wedType={EWedType.Print} />,
  Program: <Program wedType={EWedType.Lunch} />,
  Kontakty: <Contacts />,
  Řidič: <Driver />,
};
