import { Grid } from '@mui/material';
import React from 'react';

import PhotoCard from '../../Components/PhotoCard';

export default function Photos() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PhotoCard
          key={'svatebniden'}
          imagePaths={['/svatebniden']}
          zoneramaLink={'https://www.zonerama.com/znoj/Album/7685026'}
          gPhotosLink={'https://photos.app.goo.gl/JgDBpPmBTGvb3aV89'}
          oneDriveLink={
            'https://1drv.ms/u/s!ApwUpUdKbOoFhYsQW-j4Kadu14oOnA?e=0aZqjh'
          }
          title={'svatební den'}
          description={'1250 fotek'}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PhotoCard
          key={'promazáno'}
          imagePaths={['/promazano']}
          zoneramaLink={'https://www.zonerama.com/znoj/Album/7707020'}
          gPhotosLink={'https://photos.app.goo.gl/5tsVav9rkAkR7C4e7'}
          oneDriveLink={
            'https://1drv.ms/u/s!ApwUpUdKbOoFhYsRrpeZFiYJTkcP1g?e=jviQmx'
          }
          title={'svatební den - promazáno'}
          description={'752 fotek'}
        />
        <PhotoCard
          key={'pripravy'}
          imagePaths={['/pripravy']}
          zoneramaLink={'https://www.zonerama.com/znoj/Album/7686034'}
          gPhotosLink={'https://photos.app.goo.gl/En4DeKQ9LWihMppYA'}
          oneDriveLink={
            'https://1drv.ms/u/s!ApwUpUdKbOoFhYsNgQDsmeZcA9NZMQ?e=TOPbSC'
          }
          title={'přípravy'}
          description={'85 fotek'}
        />
        <PhotoCard
          key={'obrad'}
          imagePaths={['/obrad']}
          zoneramaLink={'https://www.zonerama.com/znoj/Album/7685863'}
          gPhotosLink={'https://photos.app.goo.gl/LKLhDeQPgWS6gLSt9'}
          oneDriveLink={
            'https://1drv.ms/u/s!ApwUpUdKbOoFhYsM0HkxNE_kjOtuIw?e=dG9EJE'
          }
          title={'obřad'}
          description={'285 fotek'}
        />
        <PhotoCard
          key={'zabava'}
          imagePaths={['/zabava']}
          zoneramaLink={'https://www.zonerama.com/znoj/Album/7686296'}
          gPhotosLink={'https://photos.app.goo.gl/pJ5ppuVWcpsvAxA16'}
          oneDriveLink={
            'https://1drv.ms/u/s!ApwUpUdKbOoFhYsPXkvZ6_aw4ShDwQ?e=tjt6vw'
          }
          title={'zábava'}
          description={'640 fotek'}
        />
        <PhotoCard
          key={'rodina'}
          imagePaths={['/rodina']}
          zoneramaLink={'https://www.zonerama.com/znoj/Album/7686090'}
          gPhotosLink={'https://photos.app.goo.gl/KKGJWvS1n6WVM8UV9'}
          oneDriveLink={
            'https://1drv.ms/u/s!ApwUpUdKbOoFhYsOKui_OK4JbPqHTA?e=6xTVlt'
          }
          title={'rodinné focení'}
          description={'226 fotek'}
        />
        <PhotoCard
          key={'fotokoutek'}
          imagePaths={['/fotokoutek']}
          zoneramaLink={'https://www.zonerama.com/znoj/Album/7672653'}
          gPhotosLink={'https://photos.app.goo.gl/RKekvMFE6WeZPPKC8'}
          oneDriveLink={
            'https://1drv.ms/u/s!ApwUpUdKbOoFhYsL6X0-TfKwBB1hYw?e=mLQvHv'
          }
          title={'fotokoutek'}
          description={'152 fotek'}
        />
      </Grid>
    </React.Fragment>
  );
}
