import React from 'react';

import { Grid } from '@mui/material';

import PlaceCard from '../../Components/PlaceCard';

export default function Places() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <PlaceCard
        key={'rap'}
        imagePaths={['/places/rapotin']}
        mapLink={'https://mapy.cz/s/kulefupozu'}
        gMapLink={'https://goo.gl/maps/wv54cVs2bsK4oGk68'}
        title={'Obřad'}
        description={'Kostel RAPOTÍN'}
      />
      <PlaceCard
        key={'blud'}
        imagePaths={['/places/bludov']}
        mapLink={'https://mapy.cz/s/favokefoku'}
        gMapLink={'https://goo.gl/maps/Ruwp2WUqrgf42NXB6'}
        title={'Rodinný oběd, Večerní párty'}
        description={'Kulturní dům BLUDOV'}
      />
    </Grid>
  );
}
