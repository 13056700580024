import React, { useState } from 'react';

import { Button, ButtonGroup, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { scrollToCategory } from '../../utils';
import { ICategoriesKeysProps } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#7f1734',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function DesktopMenu(props: ICategoriesKeysProps) {
  const { categories } = props;
  const { classes } = useStyles();
  const [items] = useState(categories);

  return (
    <div className={classes.root}>
      <ButtonGroup variant="text" aria-label="text primary button group">
        {items.map((buttonText, index) => (
          <Button
            color="secondary"
            key={index}
            onClick={() => scrollToCategory(index)}
          >
            {buttonText}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
